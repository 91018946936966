html,
body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 0;
}

body.fontLoaded {
  font-family: 'Poppins', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

a {
  color: #7dd3fc;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

body {
  color: #e7e8e8;
  background-color: #131316;
}

label {
  font-family: Georgia, Times, 'Times New Roman', serif;
  line-height: 1.5em;
}

#privy-dialog * {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  line-height : 20px;
  font-size: 16px;
}

@media (max-width: 440px) {
  #headlessui-dialog-panel- * {
    position: sticky !important;
  }
  [id^="headlessui-dialog-panel-"]:not([id$="\\:rn\\:"]) {
    position: sticky !important;
  }
}

/* @media only screen and (min-width: 1728px){
  .container-fluid {
    max-width: 1728px;
    margin: auto;
  }
} */

.container-fluid {
  width: 100%;
}

.sc-dAlyuH .fDGNxX{
  width: auto !important;
}
/* :root {
  --privy-border-radius-sm: 6px;
  --privy-border-radius-md: 12px;
  --privy-border-radius-mdlg: 16px;
  --privy-border-radius-lg: 24px;
  --privy-border-radius-full: 9999px;
  --privy-color-background: #1A1A22 !important; 
  --privy-color-background-2: #1A1A22 !important;
  --privy-color-foreground: hsl(60,2%,99%);
  --privy-color-foreground-2: hsl(60,2%,87%);
  --privy-color-foreground-3: hsl(0,0%,57%);
  --privy-color-foreground-4: hsl(0,0%,37%);
  --privy-color-foreground-accent: hsl(0,0%,100%);
  --privy-color-accent: hsl(237,100%,70%);
  --privy-color-accent-light: hsl(237,100%,85%);
  --privy-color-accent-dark: hsl(237,100%,64%);
  --privy-color-success: hsl(147,43%,52%);
  --privy-color-success-dark: hsl(147,43%,36%);
  --privy-color-error: hsl(7,80%,62%);
  --privy-color-warn: hsl(36,100%,65%);
  --privy-height-modal-full: 620px;
  --privy-height-modal-compact: 480px;
} */