.dashboardTab{
    color: #6096ff !important;
       border-bottom: 2px solid transparent !important;
      font-weight: 600 !important;
}
.dashboardTab-active{
    font-weight: 600 !important;
    color: white !important;
     border-bottom: 2px solid white !important
}
.leaderboardTab{
    font-weight: 600 !important;
    color: #6096ff !important;
     border-bottom: 2px solid transparent !important
}
.leaderboardTab-active{
    font-weight: 600 !important;
    color: white !important;
     border-bottom: 2px solid white !important
}
.bgColor-transparent{
    background-color: #1a1a1e !important;
}
.color-white{
    color: white !important;
}

#basic-button{ 
    }